// src/pages/CommentsPage.tsx
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  doc,
  addDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { db } from "../../firebaseConfig";
import { getAuth } from "firebase/auth";
import {
  CommentsContainer,
  FeedContainer,
  GossipCard,
  GossipHeader,
  GossipMessage,
  ReactionButton,
  ReactionContainer,
  ReactionCont,
  CategoryContainer,
} from "../Feed/FeedStyles";
import LoadingSpinner from "../../components/UI/Spinner/Spinner";
import {
  CharCount,
  ErrorBubble,
  ModalContent,
  SendButton,
  Textarea,
} from "../../components/Gossip/GossipModalStyles";
import { GossipData, CommentData, UserData } from "../../types/types";
import LoadingPage from "../Loading";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../../var/theme";
import {
  calculateAge,
  containsExcludedWords,
  isLink,
  isSpamMessage,
  maxChars,
  minChars,
} from "../../hooks/utils";
import { GlobalStyle } from "../../GlobalStyles";
import { ArrowLeft, Clock, Send, X } from "lucide-react";
import { styleVar } from "../../var/variables";
import Header from "../../components/Header/Header";
import { HS } from "../../components/Header/HeaderStyle";
import { colors } from "../../var/colors";

interface CommentPageProps {
  isOpen: boolean;
  onClose: () => void;
}

const CommentsPage: React.FC = () => {
  const { gossipId } = useParams<{ gossipId: string }>();
  const [parentGossip, setParentGossip] = useState<GossipData | null>(null);
  const [comments, setComments] = useState<CommentData[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();
  const currentUserString = localStorage.getItem("current_user");
  const currentUser: UserData = currentUserString
    ? JSON.parse(currentUserString)
    : null;
  const isDarkMode = localStorage.getItem("isDarkMode") === "true";

  const [message, setMessage] = useState("");

  const [isAtBottom, setIsAtBottom] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Obtener el gossip padre usando los mismos componentes y estilos
  useEffect(() => {
    if (gossipId) {
      const gossipRef = doc(db, "gossips", gossipId);
      getDoc(gossipRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            const parent: GossipData = {
              id: docSnap.id,
              message: data.message,
              pronoun: data.pronoun,
              age: data.age,
              country: data.country,
              timestamp: data.timestamp ? data.timestamp.toDate() : new Date(),
              reactions: data.reactions,
              isBlocked: data.isBlocked,
              userId: data.userId,
              commentCount: data.commentCount,
              collection: data.collection,
            };
            setParentGossip(parent);
          }
        })
        .catch((error) => {
          console.error("Error fetching parent gossip:", error);
        });
    }
  }, [gossipId]);

  // Escucha en tiempo real los comentarios del gossip
  useEffect(() => {
    if (!gossipId) {
      setLoading(false);
      return;
    }
    const commentsRef = collection(db, "gossips", gossipId, "comments");
    const q = query(commentsRef, orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedComments = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            message: data.message,
            timestamp: data.timestamp ? data.timestamp.toDate() : new Date(),
            user: data.user,
            reactions: data.reactions,
          } as CommentData;
        });
        setComments(fetchedComments);
        setLoading(false);
      },
      (error) => {
        console.error("Error al cargar comentarios:", error);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, [gossipId]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 200;
      setIsAtBottom(scrolledToBottom);
      if (scrolledToBottom) {
        setShowModal(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAddComment = async () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage.length < minChars) return;
    if (isSpamMessage(trimmedMessage)) {
      setError(
        "El mensaje parece spam. Intenta escribir algo más significativo."
      );
      return;
    }
    if (containsExcludedWords(trimmedMessage)) {
      setError("Tu mensaje contiene palabras no permitidas.");
      return;
    }

    if (isLink(trimmedMessage)) {
      setError("Tu mensaje contiene un enlace.");
      return;
    }

    if (message.trim() === "" || !currentUser || !gossipId) return;
    console.log("currentUser", currentUser);
    try {
      const commentsRef = collection(db, "gossips", gossipId, "comments");
      console.log(currentUser);
      await addDoc(commentsRef, {
        message: message,
        user: {
          pronoun: currentUser.pronoun || "Sin definir",
          age: calculateAge(currentUser.birthdate!),
          country: currentUser.country,
        },
        timestamp: serverTimestamp(),
        reactions: 0,
        commentCount: 0,
      });

      setMessage("");
      setShowModal(false);

      // Actualizar el contador de comentarios en el chisme padre
      const gossipRef = doc(db, "gossips", gossipId);
      const gossipSnap = await getDoc(gossipRef);
      if (gossipSnap.exists()) {
        const currentCommentCount = gossipSnap.data().commentCount || 0;
        await updateDoc(gossipRef, {
          commentCount: currentCommentCount + 1,
        });
      }
    } catch (error) {
      console.error("Error al agregar comentario:", error);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (!parentGossip && !loading) {
    return (
      <FeedContainer>
        <h2>Chisme no existe</h2>
      </FeedContainer>
    );
  }

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <GlobalStyle />
      <HS.Header>
        <HS.ContHeader>
          <HS.Logo />
          <HS.NavBar>
            <HS.NavButton onClick={() => navigate(-1)}>
              <ArrowLeft color={colors.black} size={styleVar.v18} />
            </HS.NavButton>
          </HS.NavBar>
        </HS.ContHeader>
      </HS.Header>
      <FeedContainer className="comment">
        {parentGossip && (
          <GossipCard>
            <GossipHeader>
              <span>
                {parentGossip.pronoun}, {parentGossip.age}{" "}
                {parentGossip.country && (
                  <img
                    src={`https://flagcdn.com/w40/${parentGossip.country.toLowerCase()}.png`}
                    alt="Bandera"
                    width="20"
                    height="15"
                  />
                )}
              </span>

              <CategoryContainer>
                <span className="category">
                  <Clock
                    size={styleVar.v14}
                    viewBox="0 0 24 24"
                    style={{ marginRight: "5px", verticalAlign: "middle" }}
                  />
                  {parentGossip.timestamp
                    ? `${formatDistanceToNow(parentGossip.timestamp, {
                        locale: es,
                        addSuffix: true,
                      })}`
                    : "Hace poco"}
                </span>
                {parentGossip.collection && (
                  <span
                    className={`category ${
                      parentGossip.collection === "LGBTQ+" ? "lgbt" : ""
                    }`}
                  >
                    {parentGossip.collection}
                  </span>
                )}
              </CategoryContainer>
            </GossipHeader>
            {parentGossip.isBlocked ? (
              <p style={{ color: "red", fontStyle: "italic" }}>
                Este gossip fue eliminado por no cumplir con las normas
                comunitarias.
              </p>
            ) : (
              <>
                <GossipMessage>{parentGossip.message}</GossipMessage>
                <ReactionContainer>
                  <ReactionCont>
                    <ReactionButton>🔥</ReactionButton>
                    <span>{parentGossip.reactions || 0}</span>
                  </ReactionCont>
                </ReactionContainer>
              </>
            )}
          </GossipCard>
        )}
        <CommentsContainer className="comments">
          {comments.map((comment) => (
            <GossipCard key={comment.id}>
              <GossipHeader>
                <span>
                  {comment.user?.pronoun}, {comment.user?.age}{" "}
                  {comment.user?.country && (
                    <img
                      src={`https://flagcdn.com/w40/${comment.user?.country.toLowerCase()}.png`}
                      alt="Bandera"
                      width="20"
                      height="15"
                    />
                  )}
                </span>

                <CategoryContainer>
                  <span className="category">
                    <Clock
                      size={styleVar.v14}
                      viewBox="0 0 24 24"
                      style={{ marginRight: "5px", verticalAlign: "middle" }}
                    />
                    {comment.timestamp
                      ? `${formatDistanceToNow(comment.timestamp, {
                          locale: es,
                          addSuffix: true,
                        })}`
                      : "Hace poco"}
                  </span>
                </CategoryContainer>
              </GossipHeader>
              <GossipMessage>{comment.message}</GossipMessage>
            </GossipCard>
          ))}
        </CommentsContainer>

        {!isAtBottom && !showModal && (
          <HS.NavButton
            className="write send"
            onClick={() => setShowModal((prev) => !prev)}
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: "100",
            }}
          >
            <Send color={colors.black} size={styleVar.v18} />
          </HS.NavButton>
        )}

        {showModal && (
          <ModalContent className="sticky">
            {!isAtBottom && (
              <HS.NavButton
                className="write"
                onClick={() => setShowModal((prev) => !prev)}
              >
                <X color={colors.black} size={styleVar.v18} />
              </HS.NavButton>
            )}

            <Textarea
              minLength={minChars}
              maxLength={maxChars}
              placeholder="Escribe tu comentario..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {message.length > maxChars - 50 && (
              <CharCount>{maxChars - message.length}</CharCount>
            )}
            <SendButton
              onClick={handleAddComment}
              disabled={message.trim().length < minChars}
            >
              Enviar
            </SendButton>
            {error && <ErrorBubble>{error}</ErrorBubble>}
          </ModalContent>
        )}
      </FeedContainer>
    </ThemeProvider>
  );
};

export default CommentsPage;
