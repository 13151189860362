import React from "react";
import styled, { ThemeProvider } from "styled-components";
import LoadingSpinner from "../components/UI/Spinner/Spinner";
import { darkTheme, lightTheme } from "../var/theme";

const isDarkMode = localStorage.getItem("isDarkMode") === "true";

const LoadingCont = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.whiteBone};
`;

const LoadingPage = () => {
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <LoadingCont>
        <LoadingSpinner />
      </LoadingCont>
    </ThemeProvider>
  );
};

export default LoadingPage;
