import styled from "styled-components";
import { colors } from "../../var/colors";
import { styleVar } from "../../var/variables";

export const ProfileContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const FieldContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

export const FieldLabel = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const FieldValue = styled.span`
  font-size: 1rem;
  cursor: pointer;
  padding: 8px;
  background-color: ${colors.whiteBone};
  border: 1px solid ${colors.black};
  border-radius: ${styleVar.v9};
  &:hover {
    background-color: ${colors.lightGray};
  }
`;

export const EditInput = styled.input`
  font-size: 1rem;
  padding: 8px;
  border: 1px solid ${colors.black};
  border-radius: ${styleVar.v9};
`;

export const EditSelect = styled.select`
  font-size: 1rem;
  padding: 8px;
  border: 1px solid ${colors.black};
  border-radius: ${styleVar.v9};
`;

export const SaveButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: ${colors.orange};
  border: none;
  border-radius: ${styleVar.v9};
  color: ${colors.whiteBone};
  transition: background-color 0.2s;
  &:hover {
    background-color: ${colors.yellow};
  }
`;

export const Message = styled.p`
  margin-top: 10px;
  color: ${colors.black};
`;
