import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  
  :root {
  --transition-color:  ${(props) => props.theme.black};
}
  html {
    scroll-behavior: smooth;
  }

  
#root{
    background-color: ${(props) => props.theme.whiteBone};
}

body{
    background-color: ${(props) => props.theme.whiteBone};
    color: ${(props) => props.theme.black};

    button{
    color: ${(props) => props.theme.black};
    }
}
  body.modal-open {
    overflow: hidden;
    height: 100%;
  }


  `;
