import styled, { createGlobalStyle, keyframes } from "styled-components";
import { colors } from "../../var/colors";
import { styleVar } from "../../var/variables";

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;


const scaleIn = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;




export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.overlay};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  animation: ${fade}  0.2s ;
`;

export const ModalContent = styled.div`
position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.whiteCard};
  padding: 16px;
  border-radius: ${styleVar.v9};
  border: solid ${(props) => props.theme.whiteCardBorder} 1px;
  border-bottom: solid ${(props) => props.theme.whiteCardBorder} 3px;
  position: relative;

  width: calc(100% - 100px);
  max-width: 500px;

  &.sticky {
    bottom: 25px;
    padding-bottom: 75px;
    position: sticky;
  }

  &.send {
    transition: all 1s;
  }
  
  .scaleIn{
  animation: ${scaleIn} 0.2s ease-out forwards;
  }

  @media (max-width: 768px) {
    &.sticky {
      bottom: 5px;
    }
    width: calc(100% - 45px);
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 18px;
  cursor: pointer;
`;

export const Textarea = styled.textarea`
  width: 90%;
  height: 220px;
  padding: 10px;
  font-size: 16px;
  color:  ${(props) => props.theme.black};
  background: none;
  resize: none;
  border: none;
  outline: none;
  overflow: none;
`;

export const CharCount = styled.div`
  margin: ${styleVar.v12} 0px ${styleVar.v12} 0px;
  border: solid 2px ${colors.orange};
  color: ${colors.orange};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SendButton = styled.button`
  width: calc(80% - 2px);
  height: 40px;
  color: ${(props) => props.theme.black};
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 400;
  transition: background-color 0.1s, color 0.1s;
  cursor: pointer;
  border-radius: ${styleVar.v40};
  border: 1px solid ${colors.black};
  background-color: ${colors.orange};

  &:hover {
    background-color: ${colors.yellow};
    color: ${(props) => props.theme.black};
  }

  &:disabled {
    background-color: ${colors.gray};
    color: ${(props) => props.theme.black};
    border-color: ${colors.lightGray};
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const ErrorBubble = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: #ff4d4d;
  color: white;
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 14px;
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
`;

export const CollectionContainer = styled.div`
  margin: 10px 0;
  padding-bottom: 20px;
  text-align: center;
`;

export const CollectionList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;


export const CollectionItem = styled.label<{ selected: boolean }>`
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  border: ${({ selected }) =>
    selected ? `1px solid ${colors.orange}` : "1px solid black"};

background-color: ${({ selected, theme }) => (selected ? colors.orange : theme.categoryBackground)};

  color: ${({ selected, theme }) => (selected ? colors.white : theme.gossipModalcategory)};
  transition: 0.3s ease;

  &:hover {
    background-color: ${({ selected }) =>
      selected ? colors.orangeStroke : "#ddd"};
  }
`;
