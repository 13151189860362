import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../var/colors';

const SpinnerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
    transform-origin: center; /* Centra la rotación en el centro del SVG */
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Circle = styled.circle`
  stroke: ${colors.orange}; /* Color del borde */
  stroke-width: 3;
  fill: none;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 0 150;
      stroke-dashoffset: 0;
    }
    47.5% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -16;
    }
    95%, 100% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -59;
    }
  }
`;

const LoadingSpinner = () => (
  <SpinnerWrapper>
    <svg viewBox="0 0 24 24">
      <g className="spinner_V8m1">
        <Circle cx="12" cy="12" r="9.5" />
      </g>
    </svg>
  </SpinnerWrapper>
);

export default LoadingSpinner;
