import styled from "styled-components";
import { colors } from "../../var/colors";
import { styleVar } from "../../var/variables";
import stars from "../../assets/stars.svg";

export const FeedContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.whiteBone};
  padding-bottom: 100px;

  &.comment {
    margin-top: 8px;
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-start;

  @media (max-width: 768px) {
    margin-top: 8px;
    width: 100%;

    & > *:first-child {
      margin-left: -5px;
    }
  }
`;

export const ReactionContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 768px) {
    & > *:first-child {
      margin-left: -5px;
    }
  }
`;

export const CommentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:first-child {
    border-radius: ${styleVar.v9} ${styleVar.v9} 0px 0px;
    border-bottom: none;
    margin-bottom: 0px;
  }

  & > div:last-child {
    border-radius: 0px 0px ${styleVar.v9} ${styleVar.v9};
    border-bottom: solid ${(props) => props.theme.whiteCardBorder} 3px;
  }

  & > div:not(:first-child):not(:last-child) {
    margin-bottom: 0px;
    border-radius: 0px;
    border-bottom: none;
  }

  & > div:only-child {
    border-radius: ${styleVar.v9};
    border-bottom: solid ${(props) => props.theme.whiteCardBorder} 3px;
    margin-bottom: 12px;
  }
`;

export const FilterButtons = styled.div`
  position: sticky;
  top: 60px;
  width: 100%;
  z-index: 100;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.whiteBone};
  padding: 12px 0px 12px 0px;

  @media (max-width: 768px) {
    top: 50px;
  }
`;

export const FilterButton = styled.button`
  border: none;
  background: ${(props) => props.theme.whiteCard};
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  border-radius: ${styleVar.v24};
  border: solid ${(props) => props.theme.whiteCardBorder} 1px;
  /* border-bottom: solid ${(props) => props.theme.black} 3px; */

  display: flex;
  align-items: center;
  gap: 4px;


  @media (max-width: 500px) {
    span {
      display: none;
    }
  }
`;

export const TopMessage = styled.div`
  width: calc(100% - 100px);
  max-width: 500px;
  background: ${(props) => props.theme.orangeLight};
  padding: 16px;
  border-radius: ${styleVar.v9};
  margin-bottom: 12px;

  @media (max-width: 768px) {
    width: calc(100% - 45px);
  }
`;
export const GossipMessage = styled.p`
  white-space: pre-line !important;
`;

export const GossipCard = styled.div`
  width: calc(100% - 100px);
  max-width: 500px;
  background: ${(props) => props.theme.whiteCard};
  padding: 16px;
  font-size: 16px;
  border-radius: ${styleVar.v9};
  border: solid ${(props) => props.theme.whiteCardBorder} 1px;
  border-bottom: solid ${(props) => props.theme.whiteCardBorder} 3px;
  margin-bottom: ${styleVar.v8};

  @media (max-width: 768px) {
    width: calc(100% - 45px);
  }

  &.share {
    width: 400px !important;
  }

  .category {
    display: flex;
    background: ${(props) => props.theme.categoryBackground};
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    align-items: center;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }

  @keyframes rainbowMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .lgbt {
    color: ${colors.black};
    background: linear-gradient(
      45deg,
      #ffb3b3,
      #ffcc99,
      #ffffb3,
      #b3ffb3,
      #b3d9ff,
      #d1b3ff,
      #ffb3ff
    );
    background-size: 400% 400%;
    animation: rainbowMove 6s ease infinite;
  }

  /* .category.lgbt::after {
    content: "";
    font-size: 14px;
    content: "✨";
    position: absolute;
    color: white;
    opacity: 0;
    pointer-events: none;
    animation: sparkleFade 3s infinite ease-in-out;
  }

  .category.lgbt::after {
    animation-delay: 1s;
  } */

  /* @keyframes sparkleFade {
    0% {
      opacity: 0;
      transform: scale(1) translateY(0px);
    }
    50% {
      opacity: 1;
      transform: scale(1.3) translateY(-3px);
    }
    100% {
      opacity: 0;
      transform: scale(1) translateY(0px);
    }
  }

  @keyframes sparkleRotation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  } */

  .reactions {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .details {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-direction: row;
    justify-content: space-evenly;
  }

  p {
    white-space: normal; /* Allow line breaks */
    word-wrap: break-word; /* Break long words to prevent overflow */
    overflow: hidden; /* Hide overflowed content */
    text-overflow: ellipsis; /* Optional: adds ellipsis if content overflows */
  }
`;

export const GossipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  flex-wrap: wrap;

  img {
    border-radius: 4px;
    border: solid 1px ${colors.lightBlue};
  }
`;

export const ReactionCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ReactionButton = styled.button`
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  display: flex;
  align-items: center;

  &.selected {
    background-color: ${colors.orangeLight};
  }
`;

export const SendGossipButton = styled.button`
  background: ${colors.orange};
  border: none;
  padding: ${styleVar.v9} ${styleVar.v24};
  border-radius: ${styleVar.v24};
  border: solid ${(props) => props.theme.black} 1px;
  border-bottom: solid ${(props) => props.theme.black} 3px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
`;
