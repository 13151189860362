export const styleVar = {
    v4: '4px',
    v8: '8px',
    v9: '9px',
    v12: '12px',
    v14: '14px',
    v16: '16px',
    v18: '18px',
    v24: '24px',
    v40: '40px',
    v48: '48px',
    fullBorder: '100%',

  
};
  