import React, { useState, useEffect } from "react";
import { HS } from "./HeaderStyle";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutesHelper } from "../../Main";
import {
  Send,
  User,
  MoonStar,
  Sun,
  ArrowLeft,
} from "lucide-react";
import { colors } from "../../var/colors";
import { styleVar } from "../../var/variables";
import "./ThemeTransition.css"; // Agrega una hoja de estilos para manejar la animación

interface HeaderProps {
  setModalOpen?: (open: boolean) => void;
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = ({ setModalOpen, isDarkMode, setIsDarkMode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [themeTransition, setThemeTransition] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    if (savedTheme !== null) {
      setIsDarkMode(savedTheme === "true");
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, [auth, setIsDarkMode]);

  useEffect(() => {
    localStorage.setItem("isDarkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toProfile = async () => {
    try {
      if (location.pathname === RoutesHelper.profile) {
        navigate(RoutesHelper.feed);
      } else if (location.pathname === RoutesHelper.feed) {
        navigate(RoutesHelper.profile);
      } else {
        navigate(RoutesHelper.profile);
      }
    } catch (error: any) {
      console.error("Error al navegar:", error.message);
    }
  };

  const handleThemeChange = (event: React.MouseEvent) => {
    const { clientX, clientY } = event;

    setClickPosition({ x: clientX - 50, y: clientY - 50 });

    setThemeTransition(true);

    setTimeout(() => {
      setIsDarkMode((prev) => !prev);
      setThemeTransition(false);
    }, 200); 
  };

  const inProfile = location.pathname !== RoutesHelper.profile;

  return (
    <>
      <HS.Header>
        <HS.ContHeader>
          <HS.Logo />
          <HS.NavBar>
          {isLoggedIn && (
            <>
              {inProfile && (
                <HS.NavButton
                  className="write"
                  onClick={() => setModalOpen?.(true)}
                >
                  <Send color={colors.black} size={styleVar.v18} />
                </HS.NavButton>
              )}
              <HS.NavButton  onClick={handleThemeChange}>
                {isDarkMode ? (
                  <Sun color={colors.black} size={styleVar.v18} />
                ) : (
                  <MoonStar color={colors.black} size={styleVar.v18} />
                )}
              </HS.NavButton>
              <HS.NavButton onClick={toProfile}>
                {inProfile ? (
                  <User color={colors.black} size={styleVar.v18} />
                ) : (
                  <ArrowLeft color={colors.black} size={styleVar.v18} />
                )}
              </HS.NavButton>
            </>
          )}
          </HS.NavBar>
        </HS.ContHeader>
      </HS.Header>

      {themeTransition && (
        <div
          className="theme-transition"
          style={{
            left: clickPosition.x,
            top: clickPosition.y,
          }}
        />
      )}
    </>
  );
};

export default Header;
