  export const minChars = 3;
  export const maxChars = 420; 
 
 export const formatReactions = (num: number): string => {
    if (isNaN(num)) return "0"; // Previene errores si el valor no es numérico
    
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1).replace(".0", "")}M`; // 1.2M, 2.7M
    }
    
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1).replace(".0", "")}k`; // 1.2k, 15.5k
    }
  
    return num.toString(); // 1 - 999
  };


export function calculateAge(birthdate: string | Date): number {
  const birth = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
}



export const containsExcludedWords = (text: string) => {
  const excludedWords = ["pito", "puta", "insulto"];
  return text
    .toLowerCase()
    .split(/\s+/)
    .some((word) => excludedWords.includes(word));
};

export const isSpamMessage = (text: string) => {
  const newlineCount = (text.match(/\n/g) || []).length;
  return (
    newlineCount > 5 ||
    /^(.)\1{5,}$/.test(text) ||
    /^(\d|\W|[a-zA-Z])\1+$/.test(text)
  );
};

export const isLink = (text: string) => {
  const linkPatterns = [
    "http://",
    "https://",
    "www.",
    ".com",
    ".net",
    ".org",
    ".io",
    ".dev",
    ".app",
  ];

  return text
    .toLowerCase()
    .split(/\s+/)
    .some((word) => linkPatterns.some((pattern) => word.includes(pattern)));
};
