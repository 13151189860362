import styled from "styled-components";
import { colors } from "../../var/colors";
import { styleVar } from "../../var/variables";
import logo from "../../assets/logo.svg";

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.whiteBone};
  border-bottom: 1px solid ${(props) => props.theme.categoryBackground};
  flex-direction: column;
`;

const ContHeader = styled.div`
  height: 50px;
  width: calc(100% - 100px);
  max-width: 500px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

const Logo = styled.div`
  margin-top: 10px;
  margin-left: 5px;
  width: 100px;
  height: 30px;
  background-image: url(${logo});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  filter: ${({ theme }) => (theme.black !== "#000000" ? "invert(1)" : "none")};
`;

const NavBar = styled.nav`
  top: 12px;
  right: 16px;
  display: flex;
  gap: ${styleVar.v12};
  align-items: center;
  @media (max-width: 768px) {
    gap: ${styleVar.v4};
  }
`;

const NavButton = styled.button`
  cursor: pointer;
  padding: ${styleVar.v9} ${styleVar.v24};
  border: 1px solid ${colors.black};
  border-radius: ${styleVar.v24};
  background-color: ${colors.whiteBone};
  transition: border 0.1s;
  font-size: ${styleVar.v18};

  display: flex;
  align-items: center;

  &:hover {
    border-bottom: 6px solid ${colors.black};
  }

  &.write {
    background-color: ${colors.orange};
  }

  @media (max-width: 768px) {
    padding: ${styleVar.v9} ${styleVar.v16};
  }
`;

export const HS = {
  Header,
  ContHeader,
  Logo,
  NavBar,
  NavButton,
};
