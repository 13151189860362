import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import Header from "../../components/Header/Header";
import LoadingPage from "../Loading";
import { db } from "../../firebaseConfig";
import {
  ProfileContainer,
  Title,
  FieldContainer,
  FieldLabel,
  FieldValue,
  EditInput,
  EditSelect,
  SaveButton,
  Message,
} from "./ProfileStyles";
import { LogOut } from "lucide-react";
import { HS } from "../../components/Header/HeaderStyle";
import { styleVar } from "../../var/variables";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../../GlobalStyles";
import { darkTheme, lightTheme } from "../../var/theme";
import { colors } from "../../var/colors";

interface UserData {
  pronoun?: string;
  username?: string;
  birthdate?: string;
}

interface ProfileProps {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const Profile: React.FC<ProfileProps> = ({ isDarkMode, setIsDarkMode }) => {
  const [userData, setUserData] = useState<UserData>({
    pronoun: "",
    birthdate: "",
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  // Estados para controlar si se está editando cada campo
  const [editPronoun, setEditPronoun] = useState(false);
  const [editAge, setEditAge] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const auth = getAuth();
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (userId) {
      const fetchUser = async () => {
        try {
          const docRef = doc(db, "users", userId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data() as UserData;
            setUserData({
              pronoun: data.pronoun,
              birthdate: data.birthdate,
            });
          }
        } catch (error) {
          console.error("Error al obtener datos del usuario:", error);
        }
        setLoading(false);
      };
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [userId]);

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userId) return;
    setSaving(true);
    try {
      await updateDoc(doc(db, "users", userId), {
        pronoun: userData.pronoun,
        birthdate: userData.birthdate,
      });
      setMessage("Perfil actualizado exitosamente.");
      // Desactivar los inputs de edición
      setEditPronoun(false);
      setEditAge(false);
    } catch (error) {
      console.error("Error al actualizar el perfil:", error);
      setMessage("Error al actualizar el perfil.");
    }
    setSaving(false);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const handleLogOut = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("userLoggedIn");
      navigate("/login");
    } catch (error: any) {
      console.error("Error al cerrar sesión:", error.message);
    }
    setIsLoggedIn(false);
  };

  return (
    <>
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <GlobalStyle />
        <Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
        <ProfileContainer>
          <Title>Editar Perfil</Title>
          <form onSubmit={handleSubmit}>
            <FieldContainer>
              <FieldLabel>Pronombre:</FieldLabel>
              {editPronoun ? (
                <EditSelect
                  name="pronoun"
                  value={userData.pronoun}
                  onChange={handleFieldChange}
                >
                  <option value="">Selecciona un pronombre</option>
                  <option value="He/Him">He/Him</option>
                  <option value="She/Her">She/Her</option>
                  <option value="They/Them">They/Them</option>
                  <option value="Other">Otro</option>
                </EditSelect>
              ) : (
                <FieldValue onClick={() => setEditPronoun(true)}>
                  {userData.pronoun || "Sin definir"}
                </FieldValue>
              )}
            </FieldContainer>

            <FieldContainer>
              <FieldLabel>Edad:</FieldLabel>
              {editAge ? (
                <EditInput
                  type="date"
                  name="birthdate"
                  value={userData.birthdate}
                  onChange={handleFieldChange}
                />
              ) : (
                <FieldValue onClick={() => setEditAge(true)}>
                  {userData.birthdate ? userData.birthdate : "Sin definir"}
                </FieldValue>
              )}
            </FieldContainer>

            <SaveButton type="submit" disabled={saving}>
              {saving ? "Guardando..." : "Guardar cambios"}
            </SaveButton>
          </form>
          {message && <Message>{message}</Message>}

          <div>
          <HS.NavButton onClick={handleLogOut}>
            <LogOut color={colors.black} size={styleVar.v18} />
            Log out
          </HS.NavButton>
          </div>
        </ProfileContainer>
      </ThemeProvider>
    </>
  );
};

export default Profile;
