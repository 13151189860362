import React, { useState, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Feed from "./pages/Feed/Feed";
import Login from "./components/Login/Login";
import Profile from "./pages/Profile/Profile";
import LoadingPage from "./pages/Loading";
import CommentsPage from "./pages/CommentsPage/CommentPage";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import Header from "./components/Header/Header";
const Landing = React.lazy(() => import("./pages/Landing/Landing"));
const NotFound = React.lazy(() => import("./pages/Idk/Idk"));
const MainPage = React.lazy(() => import("./pages/MainPage/MainPage"));
const Auth = React.lazy(() => import("./components/Login/Login"));
const SetUsernamePage = React.lazy(() => import("./components/Login/SetUser"));

const PrivateRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const userLoggedIn = localStorage.getItem("userLoggedIn");
  return userLoggedIn ? <>{element}</> : <Navigate to="/login" replace />;
};

export const RoutesHelper = {
  main: "/",
  feed: "/feed",
  profile: "/profile",
  comments: "secret/:gossipId",
};

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route
          path={RoutesHelper.feed}
          element={
            <PrivateRoute
              element={
                <MainPage
                  isDarkMode={isDarkMode}
                  setIsDarkMode={setIsDarkMode}
                />
              }
            />
          }
        />
        <Route
          path={RoutesHelper.profile}
          element={
            <PrivateRoute
              element={
                <Profile
                  isDarkMode={isDarkMode}
                  setIsDarkMode={setIsDarkMode}
                />
              }
            />
          }
        />
        <Route path="/login" element={<Auth />} />
        <Route
          path="/set-username"
          element={<PrivateRoute element={<SetUsernamePage />} />}
        />
        <Route path={RoutesHelper.main} element={<Landing />} />
        <Route path={RoutesHelper.comments} element={<CommentsPage />} />
        <Route path="/un" element={<SetUsernamePage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
