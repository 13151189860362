import { colors } from "./colors";

export const lightTheme = {
    whiteBone: colors.whiteBone,
    whiteCard: colors.white,
    whiteCardBorder: colors.black,
    categoryBackground: colors.lightGray,
    white: colors.white,
    gray: colors.gray,
    black: colors.black,
    orangeLight: colors.orangeLight,
    gossipModalcategory: colors.gossipModalcategory,
  };
  
export  const darkTheme = {
    whiteBone: colors.darkBone,
    whiteCard: colors.darkCard,
    whiteCardBorder: colors.darkCardBorder,
    categoryBackground: colors.darkCardBorder,
    white: colors.black,
    gray: colors.lightGray,
    black: colors.white,
    orangeLight: colors.orangeDark,
    gossipModalcategory: colors.lightGray,
  };