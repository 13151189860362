export const colors = {
    black: '#000000',
    white: '#FFFFFF',
    whiteBone: '#F6F6F6',
    darkBone: '#121212',

    darkCard: '#242424',
    darkCardBorder: '#515151',

    yellow: '#FFB500',
    green: '#BDFF00',
    greenDark: '#00a652',
    greenWpp: '#25D366',
    gray: '#555555',
    lightGray: '#efefef',
    whiteTransparent: '#ffffff00',
    transparent: 'transparent',
    
    purple: '#8a89ff',
    blue: '#0c61d7',
    orange: '#FF4D00',
    darkOrange: '#b13500',
    orangeLight: '#FFB494',
    orangeDark: '#95411E',
    orangeStroke: '#FF6624',
  
    overlay: 'rgba(0, 0, 0, 0.5)',
    lightWhiteHeader: 'rgba( 255, 255, 255, 0.5)',
    lightBlue: 'rgba(0, 107, 255, 0.1)',
    shadowBlue: 'rgba(0, 107, 255, 0.25)',
    shadow: 'rgba(0, 0, 0, 0.05)',
    shadow25: 'rgba(0, 0, 0, 0.25)',
    gradientGray: 'rgba(200,196,195,1)',
    gradientWhite: 'rgba(255,255,255,0)',


    gossipModalcategory: '#333333',
  
  };
  